body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* .page{
  display: none;
} */
 p{
            font-family: "Roboto Condensed", serif;
  font-optical-sizing: auto;
  /* font-weight: <weight>; */
  font-style: normal;
 }
@media print {
  .control-group {
    display: none;
  }
  body {
    margin: 0;
    padding: 0;
    /* width: 210 mm;
    height: 297 mm; */
   -webkit-print-color-adjust: exact;
   visibility: hidden;

  }
  .left-banner {
    background-color: "#f9a70dff !important";
  }
  .page {
    visibility: visible;
    /* width: 210mm;
    height: 297mm; */
    /* page-break-after: always; */
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
p,
hr {
  margin: 0;
  padding: 0;
}

.label {
  /* font-size: 1.5mm; */
  font-size: 1.75mm;
  font-stretch: condensed;
  font-family: sans-serif;
}
hr {
  border-color: black;
  margin-top: 0.2mm;
}
svg {
  overflow: visible;
}
